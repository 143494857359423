import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { RegexTime } from 'app/module/timetable-editor/timetable-editor.component';
import { DialogService } from 'app/service/dialog.service';
import { Observable, from, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class TicketEditorService {
  ticketEditorUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/ticket-editor';
  urlCustomer = 'https://management-api.lecip-r05-dev.net/v1/';

  crewAccount = [
    { operator: 'VHT', employeeAccountId: 'thanhdh', password: 'xxx1' },
    { operator: 'AD', employeeAccountId: 'datnt', password: 'xxx1' },
    { operator: 'COSPAS', employeeAccountId: 'vinhnd', password: 'xxx1' },
    { operator: 'DK', employeeAccountId: 'truongnt', password: 'xxx1' }
  ];

  /**
   * ticket url
   */
  private ticketUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/ticket-editor';

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  /**
   * read excels from client
   * @param files
   * @param regexTime
   * @returns
   */
  readExcelsFromClient(files: File[], regexTime: RegexTime, timeDateLine: string): Observable<any> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('regexTime', JSON.stringify(regexTime));
    formData.append('timeDateLine', timeDateLine);
    return this.http.post<any>(`${this.ticketUrl}/read-excels`, formData);
  }

  /**
   * write excel
   * @param timetable
   * @returns
   */
  public writeExcel(timetableId: Number, dayInMonth: number, month: number): Observable<any> {
    const params = new HttpParams()
      .set('language', this.commonService.getCommonObject().setting?.language)
      .set('dayInMonth', `${dayInMonth}`)
      .set('month', `${month}`);
    return this.http.post(`${this.ticketUrl}/write-excel`, timetableId, {
      responseType: 'blob',
      observe: 'response',
      params: params
    });
  }

  /**
   * Get list crew account
   */
  getListCrewAccount(): Array<any> {
    return this.crewAccount;
  }

  /**
   * addCrewAccount
   * @param formData
   * @returns
   */
  addCrewAccount(formData: any): boolean {
    this.crewAccount.push(formData);
    return true;
  }

  /**
   * deleteCrewAccount
   * @param listAccountId
   * @returns
   */
  deleteCrewAccount(listAccountId: any): boolean {
    listAccountId.forEach(accountId => {
      const index = this.crewAccount.findIndex(account => account.employeeAccountId == accountId);
      if (index > -1) this.crewAccount.splice(index, 1);
    });
    return true;
  }

  /**
   * get Languages Default
   * @returns
   */
  public getLanguagesDefault(): Observable<any> {
    return this.http.get<any>(`${this.ticketEditorUrl}/get-languages`);
  }

  /**
   * getTickets
   * @param informationAccount
   * @returns
   */
  public getTickets(informationAccount: any, appId?: string, inUseOperation?: string, isReserve?: boolean): Observable<any> {
    let isReserveParam;
    if (isReserve == true) {
      isReserveParam = true;
    } else if (isReserve == false) {
      isReserveParam = false;
    } else {
      isReserveParam = '';
    }
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams()
      .set('companyId', appId ? '' : informationAccount.compnanyid.toString())
      .set('appId', appId ?? '')
      .set('inUseOperation', inUseOperation ?? '')
      .set('isReserve', isReserveParam);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}tickets`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getTickets
   * @param informationAccount
   * @returns
   */
  public getTicketsByAppIdAndInUseOperation(informationAccount: any, appId: string, inUseOperation: number): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('appId', appId.toString()).set('inUseOperation', inUseOperation.toString());
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}tickets`, options);
  }

  /**
   * getTicket
   * @param informationAccount
   * @param ticketId
   * @returns
   */
  public getTicket(informationAccount: any, ticketId: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('ticketId', ticketId);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}ticket`, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public getTicketUpdate(informationAccount: any, ticketId: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('ticketId', ticketId);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}ticket-update`, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public getSalesTickets(informationAccount: any, ticketId?: number): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    let params;
    if (!ticketId) {
      params = new HttpParams().set('companyId', informationAccount.compnanyid.toString());
    } else {
      params = new HttpParams().set('ticketId', ticketId.toString());
    }
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}sale-tickets`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public getSalesTicket(informationAccount: any, saleTicketId: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('saleTicketId', saleTicketId);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}sale-ticket`, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public getSalesTicketUpdate(informationAccount: any, saleTicketId: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('saleTicketId', saleTicketId);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}sale-ticket-update`, options);
  }

  /**
   * postSaleTicket
   * @param informationAccount
   * @param payload
   * @returns
   */
  public postSaleTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`).append('Content-Type', 'application/json');
    return this.http.post<any>(`${informationAccount.api_url}sale-ticket`, payload, { headers: headers });
  }

  /**
   * putSaleTicket
   * @param informationAccount
   * @param payload
   * @returns
   */
  public putSaleTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`).append('Content-Type', 'application/json');
    return this.http.put<any>(`${informationAccount.api_url}sale-ticket`, payload, { headers: headers });
  }

  /**
   * deleteSaleTicket
   * @param informationAccount
   * @param payload
   * @returns
   */
  public deleteSaleTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = {
      headers: headers,
      body: payload
    };
    return this.http.delete<any>(`${informationAccount.api_url}sale-ticket`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * deleteSaleTickets
   * @param informationAccount
   * @param payloads
   * @returns
   */
  public deleteSaleTickets(informationAccount: any, payloads: any): Observable<any> {
    return from(payloads).pipe(concatMap(payload => this.deleteSaleTicket(informationAccount, payload)));
  }

  /**
   * postSaleTicketUpdate
   * @param informationAccount
   * @param payload
   * @returns
   */
  public postSaleTicketUpdate(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`).append('Content-Type', 'application/json');
    return this.http.post<any>(`${informationAccount.api_url}sale-ticket-update`, payload, { headers: headers });
  }

  /**
   * deleteSaleTicketUpdate
   * @param informationAccount
   * @param saleTicketId
   * @returns
   */
  public deleteSaleTicketUpdate(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = {
      headers: headers,
      body: payload
    };
    return this.http.delete<any>(`${informationAccount.api_url}sale-ticket-update`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * deleteSaleTicketUpdates
   * @param informationAccount
   * @param payloads
   * @returns
   */
  public deleteSaleTicketUpdates(informationAccount: any, payloads: any): Observable<any> {
    return from(payloads).pipe(concatMap(payload => this.deleteSaleTicketUpdate(informationAccount, payload)));
  }

  /**
   * getSpots
   * @param companyId
   * @param appId
   * @returns
   */
  public getSpots(informationAccount: any, appId: string): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('appId', appId);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}spots`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public postSpot(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = { headers };
    return this.http.post<any>(`${informationAccount.api_url}spot`, payload, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public putSpot(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = { headers };
    return this.http.put<any>(`${informationAccount.api_url}spot`, payload, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public deleteSpot(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = {
      headers: headers,
      body: payload
    };
    return this.http.delete(`${informationAccount.api_url}spot`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * deleteSpotsSequentially
   * @param informationAccount
   * @param payloads
   * @returns
   */
  public deleteSpotsSequentially(informationAccount: any, payloads: any[]): Observable<any> {
    return from(payloads).pipe(concatMap(payload => this.deleteSpot(informationAccount, payload)));
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public postTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http.post<any>(`${informationAccount.api_url}ticket`, payload, { headers: headers });
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public putTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http.put<any>(`${informationAccount.api_url}ticket`, payload, { headers: headers });
  }

  /**
   * deleteTicket
   * @param informationAccount
   * @param payload
   * @returns
   */
  public deleteTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = {
      headers: headers,
      body: payload
    };
    return this.http.delete(`${informationAccount.api_url}ticket`, options);
  }

  /**
   * deleteTicketsSequentially
   * @param informationAccount
   * @param payloads
   * @returns
   */
  public deleteTicketsSequentially(informationAccount: any, payloads: any[]): Observable<any> {
    return from(payloads).pipe(concatMap(payload => this.deleteTicket(informationAccount, payload)));
  }

  /**
   * deleteTicket
   * @param informationAccount
   * @param payload
   * @returns
   */
  public deleteTicketUpdate(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = {
      headers: headers,
      body: payload
    };
    return this.http.delete(`${informationAccount.api_url}ticket-update`, options);
  }

  /**
   * deleteTicketUpdatesSequentially
   * @param informationAccount
   * @param payloads
   * @returns
   */
  public deleteTicketUpdatesSequentially(informationAccount: any, payloads: any[]): Observable<any> {
    return from(payloads).pipe(concatMap(payload => this.deleteTicketUpdate(informationAccount, payload)));
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public postTicketUpdate(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http.post<any>(`${informationAccount.api_url}ticket-update`, payload, { headers: headers });
  }
  /**
   * getInformationAccount
   * @returns
   */
  public getInformationAccount(): Observable<any> {
    return this.http.get<any>(`${this.ticketEditorUrl}/get-information-account`);
  }

  /**
   * putApplication
   * @param payload
   * @param informationAccount
   * @returns
   */
  public putApplication(payload: any, informationAccount: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http
      .put<any>(`${informationAccount.api_url}application`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * postApplication
   * @param payload
   * @param informationAccount
   * @returns
   */
  public postApplication(payload: any, informationAccount: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http
      .post<any>(`${informationAccount.api_url}application`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getApplicationDesigns
   * @param appId
   * @param informationAccount
   * @returns
   */
  public getApplicationDesigns(appId: any, informationAccount: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('appId', appId);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}application-designs`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getCompanies
   * @param informationAccount
   * @returns
   */
  getCompanies(informationAccount: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('companyId', informationAccount.compnanyid);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}operators`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   *
   * @param informationAccount
   * @param payload
   * @returns
   */
  postImage(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http
      .post<any>(`${informationAccount.api_url}resources/image`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * generateQRCode
   * @param url
   * @returns
   */
  generateQRCode(url: string): Observable<any> {
    return this.http.post(`${this.ticketUrl}/generateQRCode`, url, { responseType: 'text' });
  }

  /**
   * getImageEnvironmentVariables
   * @returns
   */
  getImageEnvironmentVariables(): Observable<Map<string, number>> {
    return this.http.get<Map<string, number>>(`${this.ticketEditorUrl}/get-image-environment-variables`);
  }

  generateQRCodeInPngFormat(url: string): Observable<any> {
    return this.http.post(`${this.ticketUrl}/generateQRCodeInPngFormat`, url, { responseType: 'text' });
  }

  /**
   * Be used by TICKET-EDITOR-COMPONENT
   * get all application
   *
   * @param folderId
   * @returns
   */
  findAllApplication(informationAccount?: any): Observable<Array<any>> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('companyId', informationAccount.compnanyid.toString());
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}applications`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * Add new application
   * @param application
   */
  public createApplication(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http
      .post<any>(`${informationAccount.api_url}application`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * updateApplication
   * @param informationAccount
   * @param payload
   * @returns
   */
  public updateApplication(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http
      .put<any>(`${informationAccount.api_url}application`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * Be used by TICKET-EDITOR-COMPONENT
   * add New App Design
   *
   * @param folderId
   * @returns
   */
  createAppDesign(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http
      .post<any>(`${informationAccount.api_url}application-design`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  getAppDesignByAppId(informationAccount: any, appId: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('appId', appId.toString());
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}application-designs`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * Be used by TICKET-EDITOR-COMPONENT
   * add New App Design
   *
   * @param folderId
   * @returns
   */
  updateAppDesign(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http
      .put<any>(`${informationAccount.api_url}application-design`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * deleteAppDesignById
   * @param informationAccount
   * @param appDesignId
   * @returns
   */
  deleteAppDesignById(informationAccount: any, appDesignId: string): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = { headers, body: { appDesignId } };
    return this.http.delete<any>(`${informationAccount.api_url}application-design`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * deleteApplicationByAppId
   * @param informationAccount
   * @param appId
   * @returns
   */
  deleteApplicationByAppId(informationAccount: any, appId: string): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = { headers, body: { appId } };
    return this.http.delete<any>(`${informationAccount.api_url}application`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * deliveyAppDesign
   * @param informationAccount
   * @param appDesignId
   * @returns
   */
  deliveyAppDesign(informationAccount: any, appDesignId: string): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http
      .put<any>(`${informationAccount.api_url}application-design/apply`, { appDesignId: appDesignId }, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getListStockPartitions
   * @param informationAccount
   * @param ticketId
   * @param appId
   * @param year
   * @param month
   * @returns
   */
  getListStockPartitions(informationAccount: any, ticketId: number, appId: string, year: string, month: string): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const ticketIdParam = ticketId !== undefined && ticketId !== null ? ticketId.toString() : '';
    const appIdParam = appId ?? '';
    const yearParam = year ?? '';
    const monthParam = month ?? '';

    let params = new HttpParams();
    if (ticketIdParam) {
      params = params.set('ticketId', ticketIdParam);
    }

    if (appIdParam) {
      params = params.set('appId', appIdParam);
    }

    if (yearParam) {
      params = params.set('year', yearParam);
    }

    if (monthParam) {
      params = params.set('month', monthParam);
    }

    const options = { headers, params };

    return this.http.get<any>(`${informationAccount.api_url}stock-partitions`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getStockByStockPartitionId
   * @param informationAccount
   * @param stockPartitionId
   * @param ticketId
   * @param startDate
   * @param endDate
   * @returns
   */
  getStockByStockPartitionId(
    informationAccount: any,
    stockPartitionId: number,
    ticketId: number,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const stockPartitionIdParam = stockPartitionId !== undefined && stockPartitionId !== null ? stockPartitionId.toString() : '';
    const ticketIdParam = ticketId !== undefined && ticketId !== null ? ticketId.toString() : '';
    const startDateParam = startDate ?? '';
    const endDateParam = endDate ?? '';
    let params = new HttpParams();

    if (stockPartitionIdParam) {
      params = params.set('stockPartitionId', stockPartitionIdParam);
    }
    if (ticketIdParam) {
      params = params.set('ticketId', ticketIdParam);
    }

    if (startDateParam) {
      params = params.set('startDate', startDateParam);
    }

    if (endDateParam) {
      params = params.set('endDate', endDateParam);
    }

    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}stocks`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * importReservation
   * @param informationAccount
   * @param payload
   * @returns
   */
  importReservation(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http
      .post<any>(`${informationAccount.api_url}resources/file`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              textHasLineBreaks: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * exportReservation
   * @param informationAccount
   * @param ticketId
   * @param year
   * @param month
   * @returns
   */
  exportReservation(informationAccount: any, ticketId: number, year: number, month: number, isMulti: boolean): Observable<string> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const ticketIdParam = ticketId !== undefined && ticketId !== null ? ticketId.toString() : '';
    const yearParam = year !== undefined && year !== null ? year.toString() : '';
    const monthParam = month !== undefined && month !== null ? month.toString() : '';

    const params = new HttpParams()
      .set('ticketId', ticketIdParam)
      .set('year', yearParam)
      .set('month', monthParam);

    const options = {
      headers,
      params,
      responseType: 'text' as 'json' // Use 'text' response type to receive Base64 string
    };

    const path = !isMulti ? 'reservation-master/file' : 'section-master/file';

    return this.http.get<string>(`${informationAccount.api_url}${path}`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status === Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');

        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * updateReservationMaster
   * @param informationAccount
   * @param filePath
   * @param mode
   * @returns
   */
  public updateReservationMaster(informationAccount: any, filePath: any, mode: string, isMulti?: boolean): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const payload = {
      filePath: filePath,
      action: mode
    };
    let path = !isMulti ? 'reservation-master' : 'section-master';
    return this.http
      .put<any>(`${informationAccount.api_url}${path}`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              textHasLineBreaks: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * reservationPrices
   * @param informationAccount
   * @param stockPartitionId
   * @param ticketId
   * @param year
   * @param month
   * @returns
   */
  reservationPrices(
    informationAccount: any,
    stockPartitionId: number,
    ticketId: number,
    year: number,
    month: number,
    reservationClassId: string
  ): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const stockPartitionIdParam = stockPartitionId !== undefined && stockPartitionId !== null ? stockPartitionId.toString() : '';
    const ticketIdParam = ticketId !== undefined && ticketId !== null ? ticketId.toString() : '';
    const yearParam = year !== undefined && year !== null ? year.toString() : '';
    const monthParam = month !== undefined && month !== null ? month.toString() : '';
    const reservationClassIdParam = reservationClassId ?? '';
    let params = new HttpParams();

    if (stockPartitionIdParam) {
      params = params.set('stockPartitionId', stockPartitionIdParam);
    }
    if (ticketIdParam) {
      params = params.set('ticketId', ticketIdParam);
    }
    if (yearParam) {
      params = params.set('year', yearParam);
    }
    if (monthParam) {
      params = params.set('month', monthParam);
    }
    if (reservationClassIdParam) {
      params = params.set('reservationClassId', reservationClassIdParam);
    }

    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}reservation-prices`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * inventoryReceptionStatusChange
   * @param informationAccount
   * @param stockId
   * @param saleTypes
   * @returns
   */
  inventoryReceptionStatusChange(informationAccount: any, stockId: number, saleTypes: string[]): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const payload = {
      stockId: stockId,
      saleTypes: saleTypes
    };
    return this.http
      .put<any>(`${informationAccount.api_url}stock/sale-types`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              textHasLineBreaks: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }
}
